














import { defineComponent } from '@vue/composition-api';

import { historyCars } from '@/data/car.data';

import CarsRow from '@/components/CarsRow.vue';

export default defineComponent({
  name: 'HistoryCars',
  components: {
    CarsRow
  },
  // props: {
  // },
  setup: () => {
    return {
      historyCars
    };
  }
});
